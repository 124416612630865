/** @format */

@import 'theme/sass/index.scss';

.header-base {
  background-color: map-get($colors, 'base', 'main');
  height: 35px;
  padding-inline: 32px;
  // padding-inline-start: 0px;
  .wrap-header-logo {
    .ant-image {
      height: 100%;
      display: flex;
      position: unset;
    }
    .header-logo {
      cursor: pointer;
    }
    .bell-icon {
      vertical-align: middle;
    }
  }
  .row-custom {
    flex-wrap: nowrap;
  }
}
