/** @format */

@use 'sass:list';
@import './variables';
@mixin generate($prefix, $property, $value: $value_spacing, $unit: px) {
  @for $i from 1 through 20 {
    .#{$prefix}-#{$i} {
      #{$property}: #{$value * $i}#{$unit};
    }
  }
}
// @mixin flexibleSpacing($prefix, $unit: px) {
//   $prefixArr: str-split($prefix, '-');
//   $property: list.nth($prefixArr, 1);
//   $value: list.nth($prefixArr, -1);
//   .#{$prefix} {
//     #{$property}: calc(100% - #{$value}#{$unit});
//   }
// }
