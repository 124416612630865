/** @format */
@import 'theme/sass';
.table-container {
  width: 100%;
  height: 100%;
  .data-table {
    width: 100%;
    height: 100%;
    .ant-table {
      .ant-table-container {
        table {
          thead tr th,
          thead tr td {
            background-color: #e3f2f4;
            // background-color: map-get($colors, 'base', 'main');
          }
        }
      }
    }
  }
}
