/** @format */
@import 'theme/sass';

.tabs-default {
  height: 100%;
  .ant-tabs-content-holder {
    overflow-y: auto;
  }
  .checkbox-title {
    white-space: nowrap;
  }
}
