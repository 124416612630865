/** @format */
@import 'theme/sass';
.modal-right {
  width: 391px !important;
  padding: 0;
  float: right;
  top: 0;
  border-radius: 0;
  .ant-modal-content {
    height: 100%;
  }
  .ant-modal-content {
    border-radius: 0;
    padding: 40px 24px;
    overflow: auto;

    .header-btn-default {
      width: 81px;
      height: 20px;
      padding: 4px;
      display: flex;
      align-items: center;
      float: right;
      // .text{
      //   font-size: ;
      // }
    }

    .wrap-header {
      .wrap-title {
        .title-default {
          color: map-get($colors, 'base', 'main');
          font-weight: 600;
        }
      }
    }
  }
}
