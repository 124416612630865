/** @format */
@import 'theme/sass';

.hight-light {
  color: map-get($colors, 'text', 'light_orange');
}
.haft-width {
  margin-top: 0px;
  width: 48%;
  min-width: 200px;
  margin-right: 2%;
}
.grey-light {
  color: map-get($colors, 'text', 'grey_light');
}
.specific-upload {
  justify-content: space-evenly;
  .f-width {
    width: fit-content;
  }
}
.avatar-upload {
  justify-content: center;
  .f-width {
    width: fit-content;
  }
}
