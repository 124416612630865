/** @format */

@import 'theme/sass/index.scss';

.card-profile {
  border-bottom: 1px solid map-get($colors, 'border', 'main');
  .avatar {
    margin: auto;
  }
  .color-grey {
    color: map-get($colors, 'text', 'light');
  }
  .color-dark {
    color: map-get($colors, 'text', 'darkness');
  }
}

.personal-infor {
  .border-bottom {
    border-bottom: 1px dashed map-get($colors, 'border', 'main');
  }
  border-bottom: 1px solid map-get($colors, 'border', 'main');
}
