/** @format */
@import 'theme/sass';
.custom-pagination {
  z-index: 3;
  width: 100%;
  position: fixed;
  bottom: 0;
  right: 0;
  background-color: map-get($colors, 'background', 'light');
  padding: 8px;
  border-top: 1px solid map-get($colors, 'border', 'second_grey');
}
