/** @format */
@import './theme/sass';

.app-container {
  height: 100%;
  width: 100%;
}

.f-width {
  width: 100%;
}
.f-height {
  height: 100%;
}
.d-flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.justify-right {
  justify-content: right;
}
.justify-left {
  justify-content: left;
}
.justify-start {
  justify-content: start;
}
.justify-end {
  justify-content: end;
}
.align-center {
  align-items: center;
}

/* TEXT */
.text-left {
  text-align: left;
}

/* --------Color-------- */
.color-white {
  color: white;
}
