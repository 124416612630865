/** @format */

/** @format */

@import 'theme/sass';
.app-container {
  .ant-layout-content {
    .page-container {
      height: calc(100% - 22px - 16px);
      .filter-page {
        background-color: map-get($colors, 'background', 'light');
      }
      .page-header {
        width: 100%;
        justify-content: space-between;
        .header-title {
          text-transform: uppercase;
          font-weight: 600;
          display: block;
          margin-bottom: 7px;
        }
        // .count-apartment {
        //   color: map-get($colors, 'text', 'light');
        // }
      }
      .page-form {
        height: inherit;
        .form-content {
          height: calc(100% - 32px);
          overflow: auto;
        }
      }
    }
  }
}
