/** @format */

@import 'theme/sass';

.icon-required {
  color: red;
}

.input-default {
  height: 48px;
  width: 100%;
  &:disabled {
    background-color: map-get($colors, 'input', 'disable');
    border: 1px solid map-get($colors, 'input', 'disable');
  }
  &.image-picker {
    height: unset;
    vertical-align: middle;
    width: unset;
    .ant-upload {
      overflow: hidden;
      margin: auto;
    }
  }

  /* combobox */

  &.ant-select .ant-select-selector {
    height: 100%;
    .ant-select-selection-search-input {
      height: 100%;
    }
    .ant-select-selection {
      &-placeholder,
      &-item {
        line-height: 48px;
      }
    }
  }
  &.ant-select-multiple {
    .ant-select-selection {
      &-placeholder,
      &-item {
        line-height: unset !important;
      }
    }
  }
}

.wrap-upload {
  width: fit-content;
  .wrap-title {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
  }
}
