/** @format */

@import 'theme/sass';

.card-profile {
  .avatar-picker {
    display: flex;
    justify-content: center;
    .ant-upload-select {
      overflow: hidden;
    }
  }
}
.btn-confirm {
  width: unset !important;
  height: 48px !important;
}
