/** @format */
@import 'theme/sass';
.btn-form {
  width: 93px;
}
.hight-light {
  color: map-get($colors, 'text', 'light_orange');
}
.haft-width {
  margin-top: 0px;
  width: 48%;
}
.grey-light {
  color: map-get($colors, 'text', 'grey_light');
}
