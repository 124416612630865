/** @format */

@import 'theme/sass';

.text-default {
  color: map-get($colors, 'text', 'main');
  display: inherit;
  &.color-white {
    color: map-get($colors, 'text', 'whitee');
  }
  &.pointer {
    cursor: pointer;
  }
  &.underline {
    text-decoration: underline;
  }
  &.nowrap {
    white-space: nowrap;
  }
}
