/** @format */

@import 'theme/sass';

.btn-default {
  &.btn-filter,
  &.btn-add_filter {
    border: 1px solid map-get($colors, 'border', 'main') !important;
    .title-filter,
    &.title-add_filter {
      color: map-get($colors, 'text', 'brand_dark');
      font-size: 12px;
      font-weight: 600;
    }
  }
  &.ant-btn-default,
  &.ant-btn-default:hover {
    border: 1px solid map-get($colors, 'border', 'main_grey');
  }
  &.ant-btn-primary {
    height: 32px;
    padding: 4px 8px;
    background-color: map-get($colors, 'button', 'light');
    border-radius: 6px;
    &:hover {
      background-color: map-get($colors, 'button', 'light');
    }
    .btn-title {
      color: map-get($colors, 'text', 'whitee');
    }
    &.btn-add {
      background-color: map-get($colors, 'button', 'main');
      &:hover {
        background-color: map-get($colors, 'button', 'main');
      }
      & .title-add {
        color: map-get($colors, 'text', 'whitee');
      }
    }
    &.btn-search {
      background-color: map-get($colors, 'button', 'light_cyan');

      &:hover {
        background-color: map-get($colors, 'button', 'light_cyan');
      }
      .title-search {
        color: map-get($colors, 'text', 'whitee');
      }
    }

    &.btn-save {
      background-color: map-get($colors, 'button', 'main');
      &:hover {
        background-color: map-get($colors, 'button', 'main');
      }
      .title-save {
        color: map-get($colors, 'text', 'whitee');
      }
    }
    &.btn-cancel {
      background-color: map-get($colors, 'button', 'main_grey');
      &:hover {
        background-color: map-get($colors, 'button', 'main_grey');
      }
    }
  }
}
