/** @format */
@import 'theme/sass';
.layout-sider {
  max-height: 'calc(100vh - 61px)';
  z-index: 4;
  .btn-dropdown {
    background-color: map-get($colors, 'button', 'main');
    color: map-get($colors, 'button', 'light');
    padding: 12px 0;
    height: fit-content;
    overflow: hidden;
    &:hover {
      // background-color: map-get($colors, 'button', 'main');
      color: map-get($colors, 'button', 'light');
      font-size: 14px;
    }
  }
  .menu-sider {
    height: calc(100% - 26px - 72px);
    overflow-y: auto;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  &.ant-layout-sider-collapsed {
    .menu-sider {
      &.ant-menu.ant-menu-vertical .ant-menu-item {
        display: flex;
        align-items: center;
        width: 100%;
        padding: 0;
        justify-content: center;
        margin-inline: 0;
        span.ant-menu-title-content {
          display: none;
        }
      }
    }
  }
}
