/** @format */

@import 'theme/sass';

/** @format */
.login-page {
  background: white url('../../../assets/images/bg_login.png') no-repeat fixed right;
  .login-left {
    width: 50%;
    .wrap-image {
      height: 95%;
      display: flex;
      align-items: center;
    }
  }
  .title-login {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: map-get($colors, 'text', 'brand_dark');
  }
  .right-conent {
    width: 50%;
    .login-intro {
      color: map-get($colors, 'text', 'brand_dark');
      text-decoration: underline;
    }
    .form-login {
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
      .btn-login {
        background-color: map-get($colors, 'button', 'main');
        height: 48px;
      }
    }
  }
}
